export default{
	/**
	 * value：时间戳
	 * style：类型 y年 M月 d日 h时 m分 s秒
	 * character：分隔符
	 * s:	1毫秒  1000秒
	 **/
	 formatDate(value,style,character='/',ss=1) {
		// 时间戳转换
		if (typeof(value) == 'undefined') {
			return ''
		} else {
			let date = new Date(parseInt(value*ss))
			let y = date.getFullYear()
			let M = date.getMonth() + 1
			M = M < 10 ? ('0' + M) : M
			let d = date.getDate()
			d = d < 10 ? ('0' + d) : d
			let h = date.getHours()
			h = h < 10 ? ('0' + h) : h
			let m = date.getMinutes()
			m = m < 10 ? ('0' + m) : m
			let s = date.getSeconds()
			s = s < 10 ? ('0' + s) : s
			if(style == 'y'){
				return y
			}else if(style == 'M'){
				return y + character + M
			}else if(style == 'd'){
				return y + character + M + character + d 
			}else if(style == 'h'){
				return y + character + M + character + d + ' ' + h
			}else if(style == 'm'){
				return y + character + M + character + d + ' ' + h + ":" + m
			} else if(style == 's'){
				return y + character + M + character + d +' '+ h + ":" + m + ":" +s
			}
			
		}
	},
}