import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component:()=>import('../views/login/Login.vue')
  },{
    path:'/forget',
    name:'forget',
    component:()=>import('../views/login/forget.vue')
  },
  {
    path: '/Home',
    name:'Home',
    component:()=>import('../views/Home.vue'),
    // 二级路由
    children:[{
      path:'/index',
      name:'index',
      component:()=>import('../views/index/index.vue')
    },{
      path:'/invoice',
      naem:'invoice',
      component:()=>import('../views/invoice/index.vue')
    },{
      path:'/contract',
      naem:'contract',
      component:()=>import('../views/contract/index.vue')
    },{
      path:'/news',
      name:'news',
      component:()=>import('../views/news/index.vue')
    },{
      path:'/newsDetails',
      name:'newsDetails',
      component:()=>import('../views/news/newsDetails.vue')
    },{
      path:'/customer',
      naem:'customer',
      component:()=>import('../views/customer/index.vue')
    },{
      path:'/upload',
      naem:'upload',
      component:()=>import('../views/upload/index.vue')
    },{
      path:'/my',
      naem:'my',
      component:()=>import('../views/my/index.vue')
    },{
      path:'/edit',
      naem:'edit',
      component:()=>import('../views/my/edit.vue')
    }]
  },
]

const router = new VueRouter({
  routes
})

export default router
