import { number } from 'echarts'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // styleID:'1',
  },
  mutations: {
    // upload
    // STYLENAV(state,id){
    //   console.log(state.styleID,'');
    //   state.styleID = id
      
    // },
  },
  actions: {
  },
  modules: {
  }
})
