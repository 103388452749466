import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// 跳转
Vue.prototype.navTo = function navTo(url){
  let path = this.$route.path
  if( path !== url){
    this.$router.push(url,()=>{}, ()=>{})
  }
}
// 请求
import api from './utils/api'
Vue.prototype.$api = api
// 时间戳
import formDate from './utils/js/formDate'
Vue.prototype.$formDate = formDate.formatDate
// 查看
import see from './utils/js/see'
Vue.prototype.$see = see

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
