import axios from 'axios';
import { Message } from 'element-ui';
import Router from '@/router'


axios.defaults.timeout = 80000;
axios.defaults.baseURL = '/api.php'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 请求拦截
axios.interceptors.request.use((config) => {
    let token = localStorage.getItem('token')
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }

    // config.header['content-type'] = 'application/json;charset=UTF-8'
    return config
}, (error) => {
    return Promise.reject(error)
})
// 相应拦截
axios.interceptors.response.use((response) => {
    if (response.status == 401) {
        Message.error('登录超时')
        // 清空后台
        localStorage.clear()
        // 跳转到登陆页面
        Router.push('/')
        return Promise.reject(response);
    }
    if (response.status == 400) {
        Message.error('数据未找到')
        return Promise.reject(response);
    }
    if (response.data.error_code == 0) {
        return response.data
    } else if (response.data.error_code == 1) {
        Message.error(response.data.message)
        // return Promise.reject(response.data)

    } else if (response.data.code == 400) {
        // 提示错误
        Message.error(response.data.msg)
        // 清空后台
        localStorage.clear()
        // 跳转到登陆页面
        Router.push('/index')
        return Promise.reject(response.data)
    } else {

        Message.error(response.data.message || response.data.msg || '请求错误，请稍后再次尝试！')
        return Promise.reject(response.data)
    }
}, (err) => {
    return Promise.reject(err)
})

export default axios
