import request from '../utils/request'

export default {
    // 登陆
    Login(data) {
        return request({
            method: 'post',
            url: '/site/login',
            data: data
        })
    },
    // 修改密码
    ForgetPassword(data) {
        return request({
            method: 'post',
            url: '/user/reset-password',
            data: data
        })
    },
    // 修改用户信息
    modifyUser(data) {
        return request({
            method: 'post',
            url: '/user/update',
            data: data
        })
    },
    // 获取用户信息
    user(data) {
        return request({
            method: 'post',
            url: '/user/info',
            data: data
        })
    },
    // 查看未读信息
    findMyxiaoxi(data) {
        return request({
            method: 'post',
            url: '/tip/has-unread',
            data: data
        })
    },
    // 查看最新消息
    findNowTip(data) {
        return request({
            method: 'post',
            url: '/tip/unread',
            data: data
        })
    },
    // 数据总揽
    DataOverview(data) {
        return request({
            method: 'post',
            url: '/user/shuju',
            data: data
        })
    },
    // 平台数据
    Dataplatform(data) {
        return request({
            method: 'post',
            url: '/user/myu',
            data: data
        })
    },
    // 数据可视化
    photoUser(data) {
        return request({
            method: 'post',
            url: '/user/chart',
            data: data
        })
    },
    // 查看发票列表
    Datainvoice(data) {
        return request({
            method: 'post',
            url: '/bill/index',
            data: data
        })
    },
    // 查看我的合同
    myContert(data) {
        return request({
            method: 'post',
            url: '/agreement/index',
            data: data
        })
    },
    // 查看账单详情
    biillData(data) {
        return request({
            method: 'post',
            url: '/report/index',
            data: data
        })
    },
    // 确定账单
    dateBill(data){
        return request({
            method: 'post',
            url: '/bill/ok',
            data: data
        })
    },
    // 下载修改状态
    uploadStatuc(data){
        return request({
            method: 'post',
            url: '/bill/ok',
            data: data
        })
    },
    // 查看消息通知列表
    Listnews(data) {
        return request({
            method: 'post',
            url: '/tip/index',
            data: data
        })
    },
    // 通知详情
    TextNewsDetails(data) {
        return request({
            method: 'post',
            url: '/tip/detail',
            data: data
        })
    },
    //项目配置信息
    config(data) {
        return request({
            method: 'post',
            url: '/site/config',
            data: data
        })
    },
    // 文件上传
    upload(data){
        return request({
            method: 'post',
            url: '/upload/file',
            data: data
        })
    },
    // 上传账单及发票
    uploadData(data){
        return request({
            method: 'post',
            url: '/bill/update',
            data: data
        })
    },
    // 上传单号 /bill/ok
    itemNumner(data){
        return request({
            method: 'post',
            url: '/bill/ok',
            data: data
        })
    },
    // 上传修改状态（完善信息）
    wanshang(data){
        return request({
            method: 'post',
            url: '/bill/wanshan',
            data: data
        })
    },
}